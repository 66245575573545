import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
const Menu = () => {
  //Mobile menu
  const ShowMobileMenu = () => {
    alert("x");
  };
  return (
    <>
      <Button
        type="button"
        id="mobile-nav-toggle"
        onClick={() => {
          ShowMobileMenu();
        }}
      >
        <i className="lnr lnr-menu"></i>
      </Button>
      <div id="mobile-body-overly"></div>
      <nav id="nav-menu-container">
        <ul
          className="parent-active nav-menu nav-menu2"
          style={{ touchAction: "pan-y" }}
        >
          <li>
            <Link to={"/"}>Inicio</Link>
          </li>
          <li>
            <Link to={"/padron-inspectores"}>Padrón de Inspectores</Link>
          </li>
          <li>
            <Link to={"/protesta"}>Protesta Ciudadana</Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Menu;
