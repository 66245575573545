import React from "react";
import { Modal } from "react-bootstrap";
const MDialogo = (props) => {
  const { showModal, title, body, tamanio, footer, tipo } = props;
  return (
    <Modal show={showModal} size={tamanio}>
      <Modal.Header className={tipo}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
};

export default MDialogo;
