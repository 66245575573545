import React from "react";
import Cabezeras from "../components/Cabezeras";
import BuscadorPadron from "../components/Padron/Buscador/BuscadorPadron";
import TablaPadron from "../components/Padron/TablaPadron";
const Inspectores = () => {
  return (
    <>
      <Cabezeras pageTitle="PADRON DE INSPECTORES" />
      {/* Buscador del padrón */}
      <section className="about-section section-gap">
        {/* <BuscadorPadron /> */}
        {/* Layout inicial */}
        <TablaPadron />
      </section>
    </>
  );
};

export default Inspectores;
