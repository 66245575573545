import { gql } from "@apollo/client";

//Obtener todo el padrón de inspectores
export const OBTENER_PADRON_INSPECTORES = gql`
  query obtenerInspectores {
    obtenerInspectores {
      Clave
      FullName
    }
  }
`;
