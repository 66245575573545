import React from "react";
// Import the Slate editor factory.
import { createEditor } from "slate";
// Import the Slate components and React plugin.
import { Slate, Editable, withReact } from "slate-react";
import { useState } from "react";
import { useMemo } from "react";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import * as Yup from "yup";
//Yup

const FrmProtesta = () => {
  //Set initial state
  const editor = useMemo(() => withReact(createEditor()), []);
  const [value, setValue] = useState([]);

  //Formik
  const formik = useFormik({
    initialValues: {
      nombre: "",
      correo: "",
      direccion: "",
      telefono: "",
      denunciado: "",
      mensaje: "",
    },
    validationSchema: Yup.object({
      nombre: Yup.string().required("Tu nombre es requerido"),
      correo: Yup.string().required("Tu correo electrónico es requerido"),
      denunciado: Yup.string().required(
        "El nombre del servidor público es requerido"
      ),
    
    }),
    onSubmit: (valores) => {
      console.log(valores);
    },
  });

  return (
    <>
      <form
        className="form-area contact-form"
        id="frmprotesta"
        onSubmit={formik.handleSubmit}
      >
        <label>Tu nombre</label>
        <input
          name="nombre"
          autoComplete="nombre"
          value={formik.values.nombre}
          onChange={formik.handleChange}
          error={formik.errors.nombre && true}
          placeholder="Ingresa tu nombre con apellidos"
          className="common-input mb-20 form-control"
          type="text"
        />
        <label>Correo Electrónico (para recibir notificaciones)</label>
        <input
          name="correo"
          autoComplete="correo"
          placeholder="Correo electrónico"
          className="common-input mb-20 form-control"
          value={formik.values.correo}
          onChange={formik.handleChange}
          error={formik.errors.nombre && true}
          type="text"
        />
        <label>Teléfono</label>
        <input
          name="telefono"
          autoComplete="telefono"
          value={formik.values.telefono}
          onChange={formik.handleChange}
          error={formik.errors.nombre && true}
          placeholder="Teléfono"
          className="common-input mb-20 form-control"
          type="text"
        />
        <label>Dirección</label>
        <input
          name="direccion"
          autoComplete="direccion"
          value={formik.values.direccion}
          onChange={formik.handleChange}
          error={formik.errors.nombre && true}
          placeholder="Dirección"
          className="common-input mb-20 form-control"
          type="text"
        />
        <label>Nombre del denunciado (Servidor Público)</label>
        <input
          name="denunciado"
          autoComplete="denunciado"
          value={formik.values.denunciado}
          onChange={formik.handleChange}
          error={formik.errors.nombre && true}
          placeholder="Nombre del servidor público"
          className="common-input mb-20 form-control"
          type="text"
        />
        <label>Describa los hechos</label>
        <ReactQuill
          value={formik.values.mensaje}
          onChange={formik.handleChange}
          error={formik.errors.nombre && true}
        />
        <div>
          <button type="submit" className="genric-btn mt-30">
            {" "}
            Enviar
          </button>
          <div className="alert-msg" />
        </div>
      </form>
    </>
  );
};

export default FrmProtesta;
