import React from "react";
import Cabezeras from "../components/Cabezeras";
import FrmProtesta from "../components/ProtestaCiudadana/FrmProtesta/FrmProtesta";
const Protesta = () => {
  return (
    <>
      <Cabezeras pageTitle="PROTESTA CIUDADANA" />
      <section className="contact-section section-gap">
        <div className="container">
          <div className="row justify-content-center section-title-wrap">
            <div className="col-lg-12">
              <div className="title-img">
                <img src="/img/title-icon.png" alt="" />
              </div>
              <h1>Mecanismo de protesta ciudadana</h1>
              <p>
                Conforme a derecho y con fundamento en los artículos 1, 2, 3, 4,
                91 y 93 de la Ley General de Responsabilidades Administrativas,
                y todos los demás artículos y códigos aplicables, se utilizará
                el siguiente formato para presentar queja, denuncia y/o
                sugerencia de alguna inconformidad relacionada por los trámites
                o servicios otorgados por los servidores públicos que laboran en
                el H. Ayuntamiento de Lázaro Cárdenas, Michoacán.
              </p>
            </div>
          </div>
          <div className="row justify-content-between align-items-start">
            <div className="col-lg-6 col-md-6 contact-left"></div>
            <div className="col-lg-5 col-md-6 contact-right">
              <FrmProtesta />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Protesta;
