//Capas
import Layout from "../layout/Base";
import ContenidoPage from "../layout/Contenido";
//Paginas
import Home from "../pages/Home";
//Paginas Internas
import Inspectores from "../pages/Inspectores";
import Protesta from "../pages/Protesta";

const routes = [
  {
    path: "/",
    layout: Layout,
    component: Home,
    exact: true,
  },
  {
    path: "/padron-inspectores",
    layout: ContenidoPage,
    component: Inspectores,
    exact: true,
  },
  {
    path: "/protesta",
    layout: ContenidoPage,
    component: Protesta,
    exact: true,
  },
];

export default routes;
