import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
// import { GET_CONFIG } from "../gql/Config.gql";
const Contenido = ({ children }) => {
  //Graphql
  // const { data, loading } = useQuery(GET_CONFIG);
  useEffect(() => {
    document.body.classList.remove("pages");
    document.body.classList.add("version9");
  });
  //  if (loading) return <h1>Cargando... </h1>;

  // const { logo, direccion } = data.getDefaultConfig;
  return (
    <>
    {/* logo={logo} */}
      <Header headerId={`header2`} />

      {children}
      {/* direccion={direccion}  */}
      <Footer />
    </>
  );
};

export default Contenido;
