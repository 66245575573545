import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Table from "react-tailwind-table";
import { OBTENER_PADRON_INSPECTORES } from "../../../graphql/inspectores";
import MDialogo from "../../MDialogo/MDialogo";
const TablaPadron = () => {
  //States
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [contentModal, setContentModal] = useState(null);
  const [buttonFooter, setButtonFooter] = useState(null);
  const [headerClass, setHeaderClass] = useState("collapsed");
  const [tamnioModal, setTamanioModal] = useState("");
  //Apollo Graphql
  const { data, loading, error } = useQuery(OBTENER_PADRON_INSPECTORES);

  //Validaciones
  if (loading) return <h1>Cargando datos...</h1>;
  if (error)
    return <h1>No se puede completar la operación en estos momentos</h1>;

  const { obtenerInspectores } = data;
  //Crear la columna custom
  const rowcheck = (row, column) => {
    if (column.field === "Clave") {
      return (
        <Button size="sm" onClick={() => mostrarPerfil(row.Clave)}>
          {" "}
          Ver perfil
        </Button>
      );
    }
  };
  const columnas = [
    {
      field: "FullName",
      use: "Nombre",
      use_in_search: true,
    },
    {
      field: "Clave",
      use: " ",
    },
  ];

  //Mostrar el perfil del inspector
  const mostrarPerfil = (clave) => {
    window.open(
      "https://empleados.lazaro-cardenas.gob.mx/perfil/"+clave,
      "popup",
      "widht=870",
      "height=600",
      "menubar=no",
      "scrollbars=yes",
      "directories=no"
    );
  };
  return (
    <div className="container">
      <div className="row align-items-center justify-content-between">
        <Table
          columns={columnas}
          rows={obtenerInspectores}
          show_search={false}
          no_content_text={"No hay contenido disponible"}
          row_render={rowcheck}
          striped
          should_export={false}
        />
      </div>
      <MDialogo
        showModal={showModal}
        title={titleModal}
        body={contentModal}
        tamanio={tamnioModal}
        footer={buttonFooter}
        tipo={headerClass}
      />
    </div>
  );
};

export default TablaPadron;
